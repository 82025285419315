@import '../../theme.scss';

.qualificationsection-component {
    background: #F4F6F7;
    border-radius: 4px;
    padding: 0 15px 5px 15px;
    .qualificationsection-name {
        cursor: pointer;
        height: 40px;
        justify-content: space-between;
        .qualificationsection-title {
            font-size: 15px;
            font-weight: 900;
            color: $main-bg;
        }
        .svg-icon {
            fill: $primary-color;
            &.reversed {
                transform: rotate(180deg);
            }
        }
    }
    .qualificationsection-table {
        width: 100%;
        th {
            font-size: 13px;
            line-height: 23px;
            &:last-child {
                text-align: right;
            }
        }
        tr {
            height: 45px;
            border-top: 1px solid #E9EBEE;
            border-bottom: 1px solid #E9EBEE;
            border-collapse: collapse;
            td {
                font-size: 13px;
                p {
                    font-size: 13px;
                    color: $primary-color;
                    cursor: pointer;
                    width: fit-content;
                }
                .svg-icon {
                    width: fit-content;
                    margin-left: auto;
                    &.completed {
                        svg {
                            width: 14px;
                            fill: #24C1A5;
                        }
                    }
                    &.incompleted {
                        transform: rotate(45deg);
                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #FF0056;
                        }
                    }
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .qualificationsection-component {
        .qualificationsection-table {
            th {
                &:first-child {
                    width: 200px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .qualificationsection-component {
        .qualificationsection-table {
            th {
                &:first-child {
                    width: 150px;
                }
            }
        }
    }
}