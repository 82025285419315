@import '../../theme.scss';

.settings-page {
    height: 100%;

    .settings-container {
        max-width: 530px;
        padding: 0;
        &.availability-container {
            max-width: 100%;
        }
    }

    p {
        font-size: 15px;
        line-height: 24px;
    }

    .settings-header {
        border-bottom: 1px solid #F0F1F2;

        .head-menu {
            cursor: pointer;
            color: $primary-color;
            font-size: 11px;
            padding-bottom: 11px;
            font-weight: 900;
            white-space: nowrap;
            &.active {
                color: $primary-text;
                border-bottom: 1px solid $primary-text;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .settings-body {
        flex: 1;
        overflow-y: auto;

        h2 {
            font-size: 15px;
            line-height: 23px;
        }

        #section-profile {
            padding-top: 39px;
        }

        #section-info, #section-password {
            .btn-2 {
                margin-top: 5px;
            }
        }

        #section-how-it {
            .accordion {
                border-top: 1px solid #E7EBEE;
                &:last-child {
                    border-bottom: 1px solid #E7EBEE;
                }
            }
        }

        .option-group {
            margin-bottom: 25px;

            .option-select {
                .selectbox-container {
                    background-color: white;
                    border: 2px solid #E3E8EC;
                    height: 40px;
                    padding: 0 15px;
                    svg {
                        fill: $primary-color;
                    }
                }
                .options {
                    border-width: 2px;
                    .option {
                        height: 40px;
                        padding-left: 15px;
                    }
                }
            }

            ::placeholder {
                color: rgba(32, 54, 98, 0.4);
            }

            .option-note {
                border: 2px solid #E3E8EC;
                font-size: 15px;
                border-radius: 4px;
                resize: none;
            }

            .option-note-red {
                border: 2px solid red;
                font-size: 15px;
                border-radius: 4px;
                resize: none;
            }

            .photo-area {
                .initial-name {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    background-color: #24C1A5;
                    margin-right: 22px;
                    font-size: 23px;
                    color: white;
                    font-weight: 900;
                }
                label {
                    margin: 0;
                }
                .upload_btn {
                    color: $main-bg;
                    border-color: $main-bg;
                    padding: initial;
                    height: 40px;
                }
            }

            .check-box {
                margin-right: 15px;
            }
        }

        .check-section {
            margin-bottom: 15px;
        }

        .subject-p {
            margin-bottom: 23px;
        }

        .education-p {
            padding-bottom: 23px;
            margin-bottom: 25px;
            border-bottom: 1px solid #F0F1F2;
        }

        .education-group {
            padding-bottom: 5px;
            margin-bottom: 25px;
            border-bottom: 1px solid #F0F1F2;
        }
    }

    .availavbility-section {
        display: flex;
        .pre-setting {
            display: flex;
            .help-area {
                display: flex;
                .help-item {
                    .help-color {
                        width: 14px;
                        height: 14px;
                        border-radius: 2px;
                        margin-right: 7px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.available {
                            background: white;
                            border: 1px solid #F0F1F2;
                        }
                        &.unavailable {
                            background: #B3E1F7;
                        }
                    }
                    p {
                        font-size: 13px;
                        text-transform: capitalize;
                    }
                }
            }
            .pre-explain {
                font-size: 13px;
                line-height: 20px;
                color: #7B88A0;
            }
            .delete-all {
                color: $main-bg;
                cursor: pointer;
                width: fit-content;
                p {
                    margin-left: 7px;
                    font-size: 13px;
                }
            }
        }
        .setting-area {
            overflow-x: auto;
            .setting-table {
                width: 100%;
                font-size: 15px;
                .start-col {
                    min-width: 55px;
                    width: 55px;
                }
                .header-col {
                    min-width: 120px;
                    text-align: center;
                    font-weight: normal;
                    p {
                        font-size: 15px;
                        margin-bottom: 19px;
                    }
                    &.active {
                        color: $primary-color;
                    }
                }
                td, th {
                    border: 1px solid #F0F1F2;
                    border-collapse: collapse;
                    margin: 0;
                    padding: 0;
                    &:first-child {
                        border-left: none;
                        cursor: default;
                        padding-right: 8px;
                        text-align: right;
                        color: #7B88A0;
                    }
                }
                th {
                    border-top: none;
                    color: #7B88A0;
                }
                tbody {
                    .unavailable, .booked {
                        border-top: none;
                        border-bottom: none;
                    }
                    td {
                        height: 46px;
                        cursor: pointer;
                    }
                    .inner-schedule {
                        width: calc(100% - 8px);
                        height: 100%;
                        border-radius: 4px;
                        background: white;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .del-btn {
                            width: 23px;
                            height: 23px;
                            background: white;
                            border-radius: 50%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.2);
                            right: -27px;
                            position: absolute;
                            z-index: 10;
                            svg {
                                width: 9px;
                                fill: #7B88A0;
                            }
                        }
                        &.has-prev {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                        &.has-next {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        &.unavailable {
                            background: #B3E1F7;
                        }
                        &.booked {
                            background: #24C1A5;
                            color: white;
                        }
                        &.selected {
                            background: $primary-color;
                            box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.25);
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .settings-page {
        padding: 27px 30px 10px 30px;
        .header-container {
            justify-content: space-between;
        }
        #section-info, #section-password {
            padding-top: 38px;
            p {
                margin-bottom: 34px;
            }
        }
        #section-how-it {
            padding-top: 44px;
        }
        #section-suspend {
            p {
                margin: 38px 0 32px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 6px;
                }
                
                .type-item {
                    width: 167px;
                    margin-right: 15px;
                }

                .option-note {
                    padding: 8px 13px;
                    height: 129px;
                }

                .option-note-red {
                    padding: 8px 13px;
                    height: 129px;
                }

                .photo-area {
                    .upload_btn {
                        width: 127px;
                    }
                }

                &.picture-option {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #F0F1F2;
                    margin-bottom: 39px;
                }
            }
            .submit-btn {
                margin-bottom: 64px;
            }
        }

        .availavbility-section {
            .pre-setting {
                flex-direction: column;
                min-width: 180px;
                width: 180px;
                margin-right: 40px;
                .help-area {
                    flex-direction: column;
                    .help-item {
                        margin-bottom: 17px;
                    }
                }
                .pre-explain {
                    margin-top: 7px;
                }
                .delete-all {
                    margin: 10px 0;
                }
            }
            .setting-area {
                flex: 1;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .settings-page {
        padding: 27px 15px 10px 15px;
        .settings-header {
            ::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .header-container {
            overflow-x: auto;
            .head-menu {
                margin-right: 25px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        #section-info, #section-password {
            padding-top: 23px;
            p {
                margin-bottom: 19px;
            }
        }
        #section-how-it {
            padding-top: 9px;
        }
        #section-suspend {
            p {
                margin: 24px 0 25px;
            }
        }
        .settings-body {
            .option-group {
                .option-title {
                    margin-bottom: 10px;
                }

                .type-item {
                    width: calc(50% - 7px);
                    margin-right: auto;
                }

                .option-note {
                    padding: 11px 16px;
                    height: 140px;
                }

                .photo-area {
                    label {
                        flex: 1;
                        .upload_btn {
                            width: 100%;
                        }
                    }
                }

                &.check-option {
                    margin-top: -17px;
                }
            }
            .submit-btn {
                margin-bottom: 75px;
            }
        }
        .availavbility-section {
            flex-direction: column;
            .pre-setting {
                flex-direction: column-reverse;
                margin-bottom: 24px;
                .help-area {
                    .help-item {
                        margin-right: 52px;
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
                .pre-explain {
                    margin-bottom: 20px;
                }
            }
            .setting-table {
                .header-col {
                    p {
                        &:first-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}