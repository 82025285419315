@import '../../theme.scss';

.qualification-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .qualification-content {
                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                display: flex;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    .icon-close {
                        transform: rotate(45deg);
                    }
                }
                .modal-title {
                    font-size: 18px;
                    font-weight: 900;
                    padding-bottom: 12px;
                    border-bottom: 1px solid #F0F1F2;
                    margin-bottom: 12px;
                }
                .explain {
                    font-size: 11px;
                    color: #7B88A0;
                    margin-bottom: 19px;
                }
                .qualification-text {
                    height: 130px;
                    padding: 10px;
                    border: 2px solid #E3E8EC;
                    border-radius: 4px;
                    margin-bottom: 15px;
                    resize: none;
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .qualification-content {
        max-width: 700px;
        padding: 15px 30px;
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .qualification-content {
        max-width: 345px;
        padding: 25px 30px;
    }
}