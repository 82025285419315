@import '../../theme.scss';

.alerts-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .tutor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .tutors {
            flex: 1;
        }
        .tutor-container {
            cursor: pointer;
            .tutor {
                padding: 15px 0;
                border-bottom: 1px solid #F0F1F2;
                display: flex;
                .info {
                    flex: 1;
                    max-width: 100%;
                    .info-time-status {
                        display: flex;
                        .status-open {
                            margin-left: 20px;
                            color: green;
                        }
                        .status-closed {
                            margin-left: 20px;
                            color: red;
                        }
                    }
                    h4 {
                        font-size: 15px;
                        font-weight: 900;
                        max-width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    p {
                        font-size: 13px;
                        color: #7B88A0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin-top: 11px;
                    }
                }
            }
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .content-area {
        flex: 1;
        display: flex;
        overflow-y: auto;
        .tutor-content {
            max-width: 100%;
            flex: 1;
            margin-top: -60px;
            h3 {
                font-size: 24px;
                text-align: center;
                max-width: 480px;
            }
            p {
                margin-top: 16px;
                max-width: 400px;
                text-align: center;
            }
            .alert-comment {
                margin-top: 24px;
                border: 2px solid #E3E8EC;
                border-radius: 4px;
                max-width: 350px;
                height: 100px;
                font-size: 15px;
                width: 100%;
                padding: 10px;
                resize: none;
                &::placeholder {
                    color: #20366266;
                }
            }
            .btn-area {
                margin-top: 15px;
                display: flex;
                max-width: 350px;
                width: 100%;
                .interest-btn {
                    padding: 0;
                    height: 40px;
                    &:last-child {
                        color: $main-bg;
                        border-color: $main-bg;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .alerts-page {
        .tutors {
            margin: 0 -30px;
            padding: 0 30px;
        }
        .tutor-list {
            width: 400px;
            padding: 0 30px;
            .tutor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px;
            .btn-area {
                .interest-btn {
                    width: calc(50% - 7px);
                    &:last-child {
                        margin-left: 14px;
                    }
                }
            }
            img {
                margin: 20px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .alerts-page {
        .tutors {
            margin: 0 -15px;
            padding: 0 15px;
        }
        .tutor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 0 15px;
            &.activate {
                display: flex;
            }
            .btn-area {
                flex-direction: column;
                width: 100%;
                max-width: 350px;
                .interest-btn {
                    width: 100%;
                    &:last-child {
                        margin-top: 15px;
                    }
                }
            }
            img {
                margin-bottom: 25px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .tutor-profile {
        &.show-web-flex {
            display: none !important;
        }
        &.show-mobile-flex {
            display: flex !important;
        }
    }
    
}