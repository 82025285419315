@import '../../theme.scss';

.blockschedule-page {
    height: 100%;
    overflow-y: auto;
    display: flex;
    .pre-setting {
        .calendar-area {
            display: flex;
            .sub-calendar {
                width: 195px;
            }
            .help-area {
                .help-item {
                    .help-color {
                        width: 14px;
                        height: 14px;
                        background: #24C1A5;
                        border-radius: 2px;
                        margin-right: 7px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.available {
                            background: white;
                            border: 1px solid #F0F1F2;
                        }
                        &.unavailable {
                            background: #B3E1F7;
                        }
                    }
                    p {
                        font-size: 13px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .pre-explain {
            font-size: 13px;
            line-height: 20px;
            color: #7B88A0;
        }
        .delete-all {
            color: $main-bg;
            cursor: pointer;
            width: fit-content;
            margin-top: 10px;
            p {
                margin-left: 7px;
                font-size: 13px;
            }
        }
    }
    .setting-area {
        overflow-x: auto;
        .block-title {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .setting-table {
            width: 100%;
            font-size: 15px;
            .start-col {
                min-width: 55px;
                width: 55px;
            }
            .header-col {
                min-width: 120px;
                text-align: center;
                font-weight: normal;
                p {
                    font-size: 15px;
                    &:last-child {
                        font-size: 17px;
                        margin: 7px auto 12px;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }
                &.active {
                    color: $primary-color;
                    p {
                        &:last-child {
                            background: $primary-color;
                            color: white;
                            border-radius: 50%;
                        }
                    }
                }
            }
            td, th {
                border: 1px solid #F0F1F2;
                border-collapse: collapse;
                margin: 0;
                padding: 0;
                &:first-child {
                    border-left: none;
                    cursor: default;
                    padding-right: 8px;
                    text-align: right;
                    color: #7B88A0;
                }
            }
            th {
                border-top: none;
                color: #7B88A0;
            }
            tbody {
                .unavailable, .booked {
                    border-top: none;
                    border-bottom: none;
                }
                td {
                    height: 46px;
                    cursor: pointer;
                }
                .inner-schedule {
                    width: calc(100% - 8px);
                    height: 100%;
                    border-radius: 4px;
                    background: white;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .del-btn {
                        width: 23px;
                        height: 23px;
                        background: white;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.2);
                        right: -27px;
                        position: absolute;
                        z-index: 10;
                        svg {
                            width: 9px;
                            fill: #7B88A0;
                        }
                    }
                    &.has-prev {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                    &.has-next {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    &.unavailable {
                        background: #B3E1F7;
                    }
                    &.booked {
                        background: #24C1A5;
                        color: white;
                    }
                    &.selected {
                        background: $primary-color;
                        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.25);
                        color: white;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .blockschedule-page {
        padding: 27px 30px;
        .pre-setting {
            width: 220px;
            padding-right: 25px;
            .calendar-area {
                flex-direction: column;
                .help-area {
                    margin-top: 30px;
                    .help-item {
                        margin-bottom: 17px;
                    }
                }
            }
            .pre-explain {
                margin-top: 20px;
            }
        }
        .setting-area {
            flex: 1;
        }
    }
}

@media screen and (max-width: 991px) {
    .blockschedule-page {
        padding: 27px 15px 10px 15px;
        flex-direction: column;
        .pre-setting {
            margin-bottom: 22px;
            min-height: fit-content;
            .calendar-area {
                .help-area {
                    flex: 1;
                    padding: 30px 0 0 30px;
                    .help-item {
                        margin-bottom: 6px;
                    }
                }
            }
        }
        .setting-table {
            .header-col {
                p {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .setting-area {
            .block-title {
                font-size: 16px;
            }
        }
    }
}

.sub-calendar {
    border: none !important;
    color: #203662;
    font-family: Avenir;

    .react-calendar__navigation {
        justify-content: center;
        height: 18px;
        margin-bottom: 11px;
        position: relative;
    }
    
    .react-calendar__navigation__label {
        flex-grow: 0 !important;
        padding: 0;
        position: absolute;
        left: 0;
        font-weight: 900;
        min-width: fit-content;
        font-size: 15px;
        color: #7B88A0;
        &:hover, &:focus, &:active {
            background-color: white;
        }
    }

    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__next-button, .react-calendar__navigation__prev-button {
        display: flex;
        min-width: fit-content;
        align-items: center;
        justify-content: center;
        fill: #7B88A0;
        position: absolute;
        padding: 0;
        right: 0;
        top: 3px;
        &:hover, &:focus, &:active {
            background-color: white;
        }
    }

    .react-calendar__navigation__prev-button {
        right: 20px;
    }

    .react-calendar__month-view__weekdays {
        text-transform: capitalize;
        .react-calendar__month-view__weekdays__weekday {
            padding: 0;
            abbr {
                text-decoration: none;
                cursor: auto;
                color: #7B88A0;
                font-size: 11px;
                font-weight: normal;
            }
        }
    }

    .react-calendar__tile {
        color: #203662;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: rgba(123, 136, 160, 0.35);
    }

    .react-calendar__month-view__days {
        padding: 6px 0;
    }

    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
        background-color: white;
    }

    .react-calendar__month-view__days__day {
        padding: 0;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        font-size: 11px;
        &:hover {
            background-color: white !important;
        }

        &.react-calendar__tile--active {
            background-color: white;

            abbr {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #23A4EF;
                color: white;
                width: 26px;
                height: 26px;
                border-radius: 50%;
            }
        }
    }
}