@import '../../theme.scss';

.paymenthistory-page {
    height: 100%;
    .options-area {
        display: flex;
        .input-container {
            flex: 1;
            .search-input {
                border: none;
                .input-text {
                    font-size: 13px;
                }
            }
        }
        .count-area {
            font-size: 13px;
            color: #7B88A0;
        }
    }
    .data-area {
        flex: 1;
        .cell-1, .cell-2, .cell-3 {
            width: 10%;
        }
        .cell-4 {
            width: 15%;
        }
        .cell-5 {
            flex: 1;
        }
        .cell-6 {
            width: 15%;
        }
        .data-header {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            padding: 0 30px;
            font-size: 11px;
            font-weight: 700;
            .cell {
                padding: 17px 15px;
            }
        }
        .data-list {
            flex: 1;
            max-height: calc(100% - 50px);
            overflow-y: auto;
            font-size: 15px;
            .data-row {
                display: flex;
                padding: 0 30px;
                border-bottom: 1px solid #EDEEF0;
                .cell {
                    padding: 15px;
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
    }
    .completed-detail {
        height: 100%;
        max-height: 100%;
        overflow-y: auto; 
        .detail-list {
            border-top: 1px solid #EDEEF0;
            .detail-row {
                height: 40px;
                border-bottom: 1px solid #EDEEF0;
                .detail-title {
                    padding-right: 5px;
                }
                .detail-content {
                    flex: 1;
                    &.name {
                        color: $primary-color;
                    }
                }
            }
        }
        .group {
            h2 {
                font-size: 15px;
                margin-bottom: 7px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .paymenthistory-page {
        overflow: hidden;
        .options-area {
            align-items: center;
            padding: 0 30px;
            .search-input {
                height: 50px;
                padding-left: 0;
            }
            .count-area {
                height: 50px;
                .page-count {
                    margin-right: 9px;
                }
                .arrow-btn {
                    margin-right: 20px;
                }
                .combo-area {
                    p {
                        margin-right: 7px;
                    }
                }
            }
        }
        .data-area {
            max-height: calc(100% - 50px);
        }
        .completed-detail {
            padding: 22px 30px 10px 30px;
            h1 {
                margin-bottom: 18px;
                font-size: 23px;
            }
            .detail-list {
                margin-bottom: 39px;
                .detail-row {
                    .detail-title {
                        width: 200px;
                    }
                }
            }
            .group {
                margin-bottom: 23px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .paymenthistory-page {
        .options-area {
            flex-direction: column;
            .input-container {
                border-bottom: 1px solid #EDEEF0;
                .credit-area {
                    white-space: nowrap;
                    padding-right: 15px;
                }
            }
            .search-input {
                height: 40px;
                border-bottom: 1px solid #EDEEF0;
                padding: 0 15px;
            }
            .count-area {
                height: 40px;
                padding: 0 15px 0 4px;
                border-bottom: 1px solid #EDEEF0;
                p {
                    margin: 0 4px;
                }
                .combo-area {
                    margin-left: auto;
                    p {
                        margin: 0 15px 0 0;
                    }
                }
            }
        }
        .data-area {
            max-height: calc(100% - 80px);
            overflow-y: auto;
            .mobile-row {
                padding: 0 15px;
                min-height: fit-content;
                .data-row {
                    padding: 12px 0;
                    flex: 1;
                    display: flex;
                    .col-header {
                        font-weight: 700;
                        font-size: 11px;
                        margin-bottom: 9px;
                    }
                    p {
                        line-height: 23px;
                    }
                    .left-col {
                        width: 40%;
                        padding-right: 5px;
                    }
                    .active {
                        cursor: pointer;
                        color: $primary-color;
                        svg {
                            fill: $primary-color;
                            margin-right: 9px;
                        }
                    }
                    .right-col {
                        flex: 1;
                        font-size: 15px;
                    }
                }
                &:nth-of-type(odd) {
                    background-color: #F1F3F480;
                }
            }
        }
        .completed-detail {
            padding: 0 15px 10px 15px;
            h1 {
                margin-bottom: 18px;
                font-size: 20px;
            }
            .detail-list {
                margin-bottom: 27px;
                .detail-row {
                    .detail-title {
                        width: 135px;
                    }
                }
            }
            .group {
                margin-bottom: 18px;
            }
        }
    }
}