@import '../../theme.scss';

.messagepage-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .tutor-container {
        cursor: pointer;
        .tutor {
            padding: 15px 0;
            border-bottom: 1px solid #F0F1F2;
            display: flex;
            .avatar {
                margin-right: 15px;
            }
            .info {
                flex: 1;
                height: 45px;
                max-width: calc(100% - 65px);
                .name-area {
                    justify-content: space-between;
                    p {
                        font-size: 11px;
                        color: #7B88A0;
                    }
                    
                    &.has-new {
                        h4 {
                            font-weight: 900;
                        }
                        p {
                            font-weight: 900;
                            color: $primary-text;
                        }
                    }
                }
                h4 {
                    font-size: 15px;
                    font-weight: 500;
                    span {
                        font-size: 11px;
                        color: #7B88A0;
                        font-weight: normal;
                        margin-left: 7px;
                    }
                }
                .last-message {
                    font-size: 13px;
                    color: #7B88A0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: auto;
                }
            }
        }
        &:hover, &.selected {
            background-color: #F4F6F7;
        }
    }
    .tutor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .filter-area {
            height: 50px;
            justify-content: space-between;
            border-bottom: 1px solid #F0F1F2;
            .box-select {
                .selectbox-container {
                    background-color: white;
                    p {
                        font-size: 13px;
                        color: #97ABB5;
                    }
                }
                .options {
                    border: none;
                }
                .option {
                    font-size: 13px;
                }
            }
            .new-message {
                fill: $primary-color;
                color: $primary-color;
                cursor: pointer;
                font-size: 13px;
                .svg-icon {
                    margin-right: 7px;
                }
            }
        }
        .tutors {
            flex: 1;
        }
    }
    .content-area {
        flex: 1;
        .content-header {
            justify-content: space-between;
            height: 50px;
            border-bottom: 1px solid #F0F1F2;
            font-size: 18px;
            font-weight: 500;
            .svg-icon {
                cursor: pointer;
            }
        }
        .search-area {
            border-bottom: 1px solid #F0F1F2;
            .search-contact {
                height: 50px;
                border: none;
            }
        }
        .chat-area {
            flex: 1;
            .message-list {
                flex: 1;
                overflow-y: auto;
                display: flex;
                flex-direction: column-reverse;
                .line {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                }
                .mark {
                    position: relative;
                    background-color: white;
                    height: 100%;
                }
                .message-container {
                    margin-bottom: 23px;
                    min-height: fit-content;
                    .message-date {
                        position: relative;
                        height: 17px;
                        color: $primary-text;
                        .line {
                            top: 8px;
                            background-color: #F0F1F2;
                        }
                        .mark {
                            font-size: 13px;
                            font-weight: 500;
                            padding: 0 7px;
                            margin: 0 auto;
                        }
                    }
                    .new-message {
                        position: relative;
                        height: 21px;
                        color: $primary-color;
                        .line {
                            top: 10px;
                            background-color: $primary-color;
                        }
                        .mark {
                            width: 82px;
                            font-size: 11px;
                            border-radius: 4px;
                            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
                        }
                    }
                    .message {
                        display: flex;
                        .msg-avatar {
                            width: 40px;
                            height: 40px;
                            background: #C124B4;
                            margin-right: 15px;
                            &.other {
                                background: $primary-color;
                            }
                        }
                        .message-body {
                            flex: 1;
                            max-width: calc(100% - 55px);
                            .sender {
                                font-size: 13px;
                                color: #7B88A0;
                            }
                            p {
                                font-size: 15px;
                                line-height: 23px;
                                margin-bottom: 10px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .message-date {
                            margin-top: 0;
                        }
                        .new-message {
                            margin-top: 0;
                        }
                    }
                }
                .no-message {
                    font-size: 23px;
                    font-weight: 900;
                    max-width: 500px;
                    margin: auto;
                    height: 100%;
                    text-align: center;
                    span {
                        color: $primary-color;
                    }
                }
            }
            .message-input {
                background: rgba(8, 51, 73, 0.04);
                border-radius: 4px;
                padding: 0 15px;
                margin-right: 50px;
                ::placeholder {
                    color: #7B88A0;
                }
                .message-text {
                    flex: 1;
                    border: none;
                    outline: none;
                    height: 100%;
                    background: initial;
                    line-height: 23px;
                    padding: 14px 0;
                }
                svg {
                    margin-left: 20px;
                    fill: $primary-color;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .messagepage-page {
        .tutor-list {
            width: 400px;
            padding: 0 30px;
            .tutor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
            .tutors {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px;
            overflow-y: scroll;
            
            .search-area {
                height: 80px;
                .search-contact {
                    background-color: rgba(8, 51, 73, 0.04);
                    border: none;
                    svg {
                        fill: #7B88A0;
                    }
                    ::placeholder {
                        color: #7B88A0;
                    }
                    .input-text {
                        background-color: initial;
                    }
                }
            }

            .chat-area {
                padding-bottom: 30px;
                height: calc(100% - 50px);
                .message-list {
                    margin: 0 -30px;
                    padding: 0 30px;
                    height: calc(100% - 50px);
                    max-height: calc(100% - 50px);
                    .message-container {
                        .message-date {
                            margin: -3px 0 20px;
                        }

                        .new-message {
                            margin: -15px 0 8px;
                            .mark {
                                margin-left: auto;
                            }
                        }
                    }
                }
                .message-input {
                    .message-text {
                        min-height: 50px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .messagepage-page {
        .tutor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
            .tutors {
                margin: 0 -15px;
                padding: 0 15px;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 0 15px;
            &.activate {
                display: flex;
            }

            .chat-area {
                padding-bottom: 15px;
                height: 100%;
                .message-list {
                    margin: 0 -15px;
                    padding: 0 15px;
                    height: calc(100% - 50px);
                    max-height: calc(100% - 50px);
                    .message-container {
                        .message-date {
                            margin: -8px 0 15px;
                            .line {
                                display: none;
                            }
                        }
                        .new-message {
                            margin: -8px -15px 15px -15px;
                            .mark {
                                margin: 0 auto;
                            }
                        }
                    }
                }
                .message-input {
                    .message-text {
                        min-height: 45px;
                    }
                }
            }
        }
    }
}