@import '../../theme.scss';

.checksection-component {
    background: #F4F6F7;
    border-radius: 4px;
    padding: 0 15px;
    .checksection-name {
        cursor: pointer;
        height: 40px;
        justify-content: space-between;
        .checksection-title {
            font-size: 15px;
            font-weight: 900;
            color: $main-bg;
        }
        .svg-icon {
            fill: $primary-color;
            &.reversed {
                transform: rotate(180deg);
            }
        }
    }
    .checksection-content {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5px;
        .checksection-item {
            width: 50%;
            max-width: 50%;
            overflow: hidden;
            padding-right: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 30px;
            .check-box {
                min-width: 17px !important;
                width: 17px !important;
                height: 17px !important;
                border-width: 1px;
            }
            p {
                font-size: 15px;
                line-height: initial;
                margin-left: 8px;
            }
        }
    }
}