@import '../../theme.scss';

.inputbox-component {
    width: 100%;
    position: relative;

    .phoneInput-container {
        width: 100%;
        border: 2px solid #E3E8EC;
        background: white;
        overflow: hidden;
        height: 40px;
        padding: 0 11px;
        border-radius: 4px;
        z-index: 2;

        .phone {
            border-width: 0px;
            font-family: Maax;
            color: black;
            &:focus {
                box-shadow: none;
            }
        }

        .dropdown {
            z-index: 9;
        }

        .buttonClass {
            background-color: transparent;
            border-width: 0px;
            margin-left: 10px;
        }

        ::placeholder {
            color: #C5CBD7;
        }
    
    }
}

@media screen and (min-width: 992px) {
    .phoneInput-container {
        height: 65px;
        padding-left: 22px;

        .phone {
            font-size: 20px;;
        }

        .buttonClass {
                
        }
    }
}

@media screen and (max-width: 991px) {
    .phoneInput-container {
        height: 50px;
        padding-left: 17px;

        .phone {
            font-size: 16px;
        }

        .dropdown {
            width: 254px;
            z-index: 3;
        }
    }
}