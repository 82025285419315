@import '../../theme.scss';

.help-page {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    .help-container {
        max-width: 530px;
        padding: 0;
    }
    .accordion {
        border-top: 1px solid #E7EBEE;
        &:last-child {
            border-bottom: 1px solid #E7EBEE;
        }
    }
}

@media screen and (min-width: 992px) {
    .help-page {
        padding: 27px 30px 10px 30px;
    }
}

@media screen and (max-width: 991px) {
    .help-page {
        padding: 27px 15px 10px 15px;
    }
}