@import '../../theme.scss';

@import '../../theme.scss';

.confirm-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .confirm-content {
                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                display: flex;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    .icon-close {
                        transform: rotate(45deg);
                    }
                }
                .content-area {
                    h1 {
                        text-align: center;
                    }
                    h2 {
                        margin-top: 0px;
                    }
                    .confirm-btn-area{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        .confirm-btn {
                            font-weight: 500;
                            border-radius: 4px;
                            color: white;
                            min-height: 40px;
                            width: 45%;
                            border: none;
                            outline: none;
                            font-family: inherit;
                            background: #23A4EF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                    .textarea-note {
                        width: 100%;
                        height: 100px;
                        border: 2px solid #E3E8EC;
                        border-radius: 4px;
                        font-size: 15px;
                        padding: 5px 10px;
                        resize: none;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .confirm-content {
        max-width: 700px;
        .content-area {
            padding: 37px 45px 45px;
            flex: 1;

            h1 {
                font-size: 24px;
                margin-bottom: 27px;
            }
            h2 {
                font-size: 20px;
                margin-top: 34px;
            }
            
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .confirm-content {
        max-width: 345px;
        flex-direction: column;
        .content-area {
            padding: 19px 30px 30px;
            h1 {
                font-size: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
            h2 {
                font-size: 16px;
                margin-top: 21px;
            }
            .confirm-btn-area{
                .confirm-btn {
                    font-size: 14px;
                }
            }
        }
    }
}
