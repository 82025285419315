@import '../../theme.scss';

.porting-page {
    height: 100%;
    .porting-container {
        max-width: 530px;
    }
    .porting-body {
        flex: 1;
        overflow-y: auto;
        align-items: center;
        display: flex;
        h2 {
            font-size: 15px;
            line-height: 23px;
        }
        .btn-2 {
            margin-top: 5px;
        }
        .option-group {
            margin-bottom: 25px;
        }
    }
}

@media screen and (min-width: 992px) {
    .porting-page {
        padding: 27px 30px 10px 30px;
        .porting-body {
            .option-group {
                .option-title {
                    margin-bottom: 6px;
                }
            }
            .submit-btn {
                margin-bottom: 64px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .porting-page {
        padding: 27px 15px 10px 15px;
        .porting-body {
            .option-group {
                .option-title {
                    margin-bottom: 10px;
                }
            }
            .submit-btn {
                margin-bottom: 75px;
            }
        }
    }
}