@import '../../theme.scss';

.rateplan-page {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    h1 {
        font-size: 23px;
        font-weight: 900;
        text-align: center;
    }
    .plan-area {
        max-width: 100%;
        table {
            color: $primary-text;
            margin-top: 23px;
            th {
                font-weight: 900;
                font-size: 18px;
                border-bottom-width: 1px;
                .top-border {
                    display: none;
                }
                &.active-col {
                    &:before, &:after, .top-border {
                        content: " ";
                        position: absolute;
                    }
                    &:before, &:after {
                        height: calc(100% + 1px);
                        top: 0;
                        width: 15px;
                    }
                    &:before {
                        box-shadow: -15px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
                        left: -15px;
                    }
                    &:after {
                        box-shadow: 15px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
                        right: -15px;
                    }
                    .top-border {
                        display: block;
                        box-shadow: 0 -15px 15px -15px rgba(0, 0, 0, 0.12) inset;
                        height: 15px;
                        top: -16px;
                        left: -5px;
                        right: -5px;
                        border-top-left-radius: 25px;
                        border-top-right-radius: 25px;
                    }
                }
            }
            th, td {
                border-color: #F0F1F2;
                vertical-align: middle;
                position: relative;
                &.disabled {
                    background: rgba(244, 246, 247, 0.5);
                    color: $secondary-text;
                }
            }
            td {
                font-size: 15px;
                position: relative;
                .bottom-border {
                    display: none;
                }
                &.active-col {
                    &:before, &:after, .bottom-border {
                        content: " ";
                        position: absolute;
                    }
                    &:before, &:after {
                        height: calc(100% + 1px);
                        top: 0;
                        width: 15px;
                    }
                    &:before {
                        box-shadow: -15px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
                        left: -15px;
                    }
                    &:after {
                        box-shadow: 15px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
                        right: -15px;
                    }
                    .bottom-border {
                        display: block;
                        box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.12) inset;
                        height: 15px;
                        bottom: -16px;
                        left: -7px;
                        right: -7px;
                        border-bottom-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                    }
                }
            }
            .select-btn {
                &:hover {
                    background: $primary-color;
                    color: white;
                }
                &.disabled {
                    background: none;
                    background: rgba(32, 54, 98, 0.06);
                    color: rgba(32, 54, 98, 0.43);
                    border: none;
                }
            }
        }
    }
    .payment-area {
        .form-group {
            margin-bottom: 25px;
            .btn-2 {
                width: 100%;
                margin-top: auto;
            }
        }
        .back-icon {
            position: absolute;
            top: 27px;
            left: 32px;
            fill: $primary-color;
            cursor: pointer;
        }
        .row {
            margin: 0;
        }
        .toggle-area {
            display: flex;
            .custom-toggle {
                display: inline-block;
                margin-right: 14px;
            }
            p {
                font-size: 15px;
                line-height: 24px;
            }
        }
        .policy {
            margin-top: 31px;
            p {
                font-size: 13px;
                line-height: 21px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .plan-area {
        padding: 24px 15px 0 30px;
        table {
            th, td {
                padding-left: 30px;
            }
            .select-btn {
                margin: 18px 0;
            }
        }
    }
    .payment-area {
        max-width: 562px;
        padding-top: 37px;

        h1 {
            margin-bottom: 26px;
        }
    }
}

@media screen and (max-width: 991px) {
    .plan-area {
        padding: 24px 0 0 15px;
        table {
            .select-btn {
                margin: 3px 0;
            }
        }
    }
    .payment-area {
        padding: 11px 7.5px 0 7.5px;
    }
}