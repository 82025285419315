@import '../../theme.scss';

.clientfeedback-component {
    display: flex;
    color: $primary-text;
    background-color: white;
    padding: 15px 0;
    color: #7B88A0;
    font-size: 13px;
    border-bottom: 1px solid #F0F1F2;
    min-height: fit-content;
    &:first-child {
        border-top: 1px solid #F0F1F2;
    }

    .initial {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: white;
        font-weight: 900;
        border-radius: 50%;
        text-transform: uppercase;
        margin-right: 15px;
    }
    .initial-1 {
        background-color: #C124B4;
    }
    .initial-2 {
        background-color: #C12424;
    }
    .initial-3 {
        background-color: #91C124;
    }
    .user-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 60px);
        .name {
            font-weight: 900;
            color: $primary-text;
        }
        .sub-info {
            color: #7B88A0;
            display: flex;
            align-items: center;
            .subject {
                margin-right: 6px;
            }
            .star-container {
                vertical-align: top !important;
            }
        }
        .description {
            line-height: 20px;
        }
    }
}

@media screen and (min-width: 992px) {
    .clientfeedback-component {
        .user-info {
            .basic {
                justify-content: space-between;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 8px;
            }
            .sub-info {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .clientfeedback-component {
        .user-info {
            .basic {
                div {
                    &:first-child {
                        margin-bottom: 6px;
                    }
                }
                margin-bottom: 21px;
            }
            .sub-info {
                margin-bottom: 6px;
            }
            .description {
                margin-top: 16px;
            }
        }
    }
}