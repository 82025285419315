@import '../../theme.scss';

.withdraw-page {
    height: 100%;
    overflow-y: auto;
    .sub-section {
        max-width: 530px;
        width: 100%;
        h3 {
            text-align: center;
            font-size: 23px;
        }
        h2 {
            font-size: 15px;
            line-height: 23px;
        }
        .sub-title {
            font-size: 23px;
            font-weight: 900;
            margin-bottom: 18px;
        }
        .btn-2 {
            margin-bottom: 20px;
        }
        .section-info {
            font-size: 18px;
        }
        .edit-btn {
            text-decoration-line: underline;
            color: #007bff;
            cursor: pointer;
            margin-bottom: 20px;
        }
        .option-list {
            .option-row {
                display: flex;
            }
            .option-group {
                margin-bottom: 25px;
                min-height: fit-content;

                .option-select {
                    .selectbox-container {
                        background-color: white;
                        border: 2px solid #E3E8EC;
                        height: 40px;
                        padding: 0 15px;
                        svg {
                            fill: $primary-color;
                        }
                    }
                    .options {
                        border-width: 2px;
                        .option {
                            height: 40px;
                            padding-left: 15px;
                        }
                    }
                }

                .type-item {
                    cursor: pointer;
                    height: 40px;
                    border: 2px solid #E7EBEE;
                    border-radius: 4px;
                    text-align: center;
                    &.active {
                        background-color: $primary-text;
                        color: white;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }

                ::placeholder {
                    color: rgba(32, 54, 98, 0.4);
                }

                .option-note {
                    border: 2px solid #E3E8EC;
                    font-size: 15px;
                    height: 140px;
                    border-radius: 4px;
                }
                .date-area {
                    display: flex;
                }
            }
        }
        .rc-slider-rail, .rc-slider-track {
            height: 8px;
            border-radius: 7.5px;
        }
        .rc-slider-rail {
            background: #E3E8EC;
        }
        .rc-slider-track {
            background: #23A4EF;
        }

        .rc-slider-handle {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: white;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            top: 1px;
            .inner-handle {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background: #23A4EF;
            }
        }

        .back-icon {
            width: 13px;
            height: 20px;
            margin-top: 10px;
            margin-right: 20px;
        }
    }
}

@media screen and (min-width: 992px) {
    .withdraw-page {
        padding: 27px 30px 10px 30px;
        .sub-section {
            h3 {
                margin-bottom: 31px;
            }
            
            .option-list {
                .option-row {
                    justify-content: space-between;
                    align-items: center;
                    .option-group {
                        flex: 1;
                        margin-right: 15px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .option-group {
                    .option-title {
                        margin-bottom: 6px;
                    }
                    
                    .type-item {
                        width: 167px;
                        margin-right: 15px;
                    }

                    .option-note {
                        padding: 8px 13px;
                    }

                    .date-area {
                        align-items: center;
                        justify-content: space-between;
                        .option-select {
                            flex: 1;
                            margin-right: 15px;
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
                .submit-btn {
                    margin-top: 5px;
                }
            }
            .rc-slider {
                margin: 32px 0 31px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .withdraw-page {
        padding: 27px 15px 10px 15px;
        .sub-section {
            h3 {
                margin-bottom: 18px;
            }
           
            .option-list {
                .option-row {
                    flex-direction: column;
                }
                .option-group {
                    .option-title {
                        margin-bottom: 10px;
                    }

                    .type-item {
                        width: calc(50% - 7px);
                        margin-right: auto;
                    }

                    .option-note {
                        padding: 11px 16px;
                    }

                    .date-area {
                        flex-direction: column;
                        .option-select {
                            margin-bottom: 15px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .submit-btn {
                    margin-top: -10px;
                }
            }
            .rc-slider {
                margin: 23px 0 26px;
            }
        }
    }
}