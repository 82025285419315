.autocharge-page {
    height: 100%;
    p {
        text-align: center;
        font-size: 23px;
    }
    .check-area {
        display: flex;
        .check-box {
            margin-right: 9px;
        }
        .explain {
            text-align: center;
            line-height: 23px;
            font-size: 15px;
        }
    }
    .save-btn {
        height: 50px;
        padding: initial;
        font-size: 15px;
        border-color: #083349;
        color: #083349;
    }
}

@media screen and (min-width: 992px) {
    .autocharge-page {
        padding: 0 30px;
        p {
            max-width: 600px;
            line-height: 37px;
        }
        .check-area {
            margin-top: 21px;
        }
        .save-btn {
            width: 141px;
            margin-top: 31px;
        }
    }
}

@media screen and (max-width: 991px) {
    .autocharge-page {
        padding: 0 15px;
        p {
            line-height: 35px;
        }
        .check-area {
            margin-top: 16px;
        }
        .save-btn {
            width: 100%;
            margin-top: 23px;
        }
    }
}