@import '../../theme.scss';

.toggle-component {
    position: relative;
    border-radius: 22.5px;
    cursor: pointer;
    background: $primary-color;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.05);
    min-width: 40px;
    height: 21px;
  
    .handle {
        position: absolute;
        top: 2px;
        left: 22px;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: white;
        transition: left 0.15s;
        -webkit-transition: left 0.15s;
        -moz-user-select: none;
        -webkit-user-select: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  
    &.disable {
      background: grey !important;
    }
  
    &.off {
        background: #E7EBEE;
        
        .handle {
            left: 1.5px;
        }
    }
}