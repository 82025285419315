@import '../../theme.scss';

.searchbox-component {
    width: 100%;
    position: relative;

    .searchbox-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        border: 2px solid #E3E8EC;
        background: white;
        height: 40px;
        padding: 0 11px;
        .start-icon {
            fill: #7B88A0;
            margin-right: 11px;
        }
    
        ::placeholder {
            color: #C5CBD7;
        }
    
        .search-text {
            flex: 1;
            height: 100%;
            border: none;
            outline: none;
        }

        .last-icon {
            fill: $primary-color;
        }
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    .options-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: 2px solid #E3E8EC;
        border-right: 2px solid #E3E8EC;
        border-bottom: 2px solid #E3E8EC;
        position: absolute;
        z-index: 15;
        max-height: 200px;
        overflow-y: auto;

        .option {
            display: flex;
            align-items: center;
            color: #203662;
            background: white;
            height: 40px;
            padding-left: 11px;
            cursor: pointer;

            &:hover {
                color: white;
                background: #203662 !important;
            }
        }
    }
}