@import '../../theme.scss';

.mytutors-page {
    display: flex;
    flex: 1;
    height: 100%;
    .scroll-area {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    h2 {
        font-size: 15px;
        line-height: 23px;
    }
    .tutor-list {
        .search-contact {
            height: 50px;
            border: none;
            border-bottom: 1px solid #F0F1F2;
            padding: 0;
            svg, ::placeholder {
                fill: #7B88A0;
                color: #7B88A0;
            }
            .input-text {
                font-size: 13px;
            }
        }
        .tutors {
            flex: 1;
        }
        .tutor-container {
            cursor: pointer;
            .tutor {
                padding: 15px 0;
                border-bottom: 1px solid #F0F1F2;
                display: flex;
                .avatar {
                    margin-right: 16px;
                }
                .info {
                    flex: 1;
                    height: 45px;
                    max-width: calc(100% - 60px);
                    h4 {
                        font-size: 15px;
                        font-weight: 900;
                    }
                    p {
                        font-size: 13px;
                        color: #7B88A0;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &:hover, &.selected {
                background-color: #F4F6F7;
            }
        }
    }
    .content-area {
        flex: 1;
        display: flex;
        overflow-y: auto;
        .tutor-content {
            max-width: 100%;
        }
        .client-info {
            border-bottom: 1px solid #EDEEF0;
            padding: 10px 0;
            display: flex;
            flex: 1;
            &.inside-info {
                padding: 0;
                border: none;
            }
            &.client-header {
                padding: 13px 0;
                h4 {
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .client-value {
                flex: 1;
                word-break: break-word;
            }
            .link-p {
                color: $primary-color;
                margin-bottom: 18px;
                cursor: pointer;
                max-width: fit-content;
            }
            .client-sub-info {
                display: flex;
            }
            .upload-btn {
                color: $main-bg;
                border-color: $main-bg;
                margin-top: 10px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .mytutors-page {
        .tutors {
            margin: 0 -30px;
            padding: 0 30px;
        }
        .tutor-list {
            width: 400px;
            padding: 0 30px;
            .tutor-container {
                margin: 0 -30px;
                padding: 0 30px;
            }
        }
        .content-area {
            width: calc(100% - 400px);
            box-shadow: -4px 0 30px rgba(0, 0, 0, 0.13);
            padding: 0 30px;
            .client-info {
                .client-property {
                    width: 300px;
                }
                .client-value {
                    max-width: calc(100% - 300px);
                    display: flex;
                    justify-content: space-between;
                    .btn-2 {
                        min-height: 25px;
                        width: 47px;
                        padding: 0;
                        font-size: 13px;
                        font-weight: normal;
                    }
                }
                .client-sub-info {
                    .link-p {
                        width: 50%;
                        max-width: 50%;
                    }
                }
                .upload-btn {
                    min-width: 57px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .mytutors-page {
        .tutors {
            margin: 0 -15px;
            padding: 0 15px;
        }
        .tutor-list {
            width: 100%;
            padding: 0 15px;
            display: none;
            &.activate {
                display: flex;
            }
        }
        .content-area {
            width: 100%;
            display: none;
            padding: 0 15px;
            &.activate {
                display: flex;
            }
            .client-info {
                min-height: fit-content;
                .client-property {
                    width: 135px;
                }
                .client-value {
                    max-width: calc(100% - 135px);
                    padding-left: 15px;
                }
                .book-btn {
                    min-height: 25px;
                    width: 47px;
                    padding: 0;
                    font-size: 13px;
                    font-weight: normal;
                    justify-content: center;
                }
                
                .client-sub-info {
                    flex-direction: column;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .tutor-profile {
        &.show-web-flex {
            display: none !important;
        }
        &.show-mobile-flex {
            display: flex !important;
        }
    }
    
}