.creditcard-page {
    height: 100%;
    padding: 0 15px;
    p {
        font-size: 23px;
        line-height: 37px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
    }
    .box-container {
        position: relative;
        .card-box {
            position: relative;
            border-radius: 10px;
            border: 1.5px solid #EDEEF0;
            background-color: #F8F9F9;
            p {
                font-size: 15px;
                text-align: left;
                line-height: initial;
            }
            .second-line {
                margin-top: auto;
            }
            .sub-col {
                position: relative;
                .slash {
                    position: absolute;
                    font-size: 16px;
                    font-family: CircularStd;
                    color: #C1C9D1;
                }
                img {
                    width: 60%;
                    height: 80%;
                }
            }
            input {
                width: 100%;
                text-align: center;
                background-color: white;
                border: 1px solid #EDEEF0;
                border-radius: 3px;
            }
            .break-line {
                background: rgba(32, 54, 98, 0.048);
            }
            &:first-child {
                z-index: 2;
            }
            &:last-child {
                z-index: 1;
            }
        }
    }
    .save-btn {
        margin-top: 30px;
        border-color: #083349;
        color: #083349;
        padding: initial;
    }
}

@media screen and (min-width: 992px) {
    .creditcard-page {
        .card-box {
            width: 350px;
            height: 205px;
            padding: 23px 20px 21px;
            p {
                margin-bottom: 5px;
            }
            .slash {
                top: 11px;
                right: -3px;
            }
            .row {
                margin: 0 -5px;
                .sub-col {
                    padding: 0 5px;
                    input {
                        height: 40px;
                    }
                }
            }
            .break-line {
                height: 60px;
                margin: 13px -20px 0;
            }
            &:first-child {
                margin-right: 121px;
            }
            &:last-child {
                margin-left: 121px;
                margin-top: -183px;
            }
        }
        .save-btn {
            width: 141px;
            height: 50px;
        }
    }
}

@media screen and (max-width: 991px) {
    .creditcard-page {
        max-width: 375px;
        margin: 0 auto;
        .box-container {
            width: 100%;
        }
        .card-box {
            width: 87%;
            min-height: 178px;
            padding: 20px 17px 18px;
            p {
                margin-bottom: 4px;
            }
            .slash {
                top: 9.5px;
                right: -2px;
            }
            .row {
                margin: 0 -4px;
                .sub-col {
                    padding: 0 4px;
                    input {
                        height: 35px;
                    }
                }
            }
            .break-line {
                height: 52px;
                margin: 11px -17px 0;
            }
            &:first-child {
                margin-right: auto;
            }
            &:last-child {
                margin-left: auto;
                margin-top: -88px;
            }
        }
        .save-btn {
            width: 100%;
            height: 40px;
        }
    }
}