@import '../../theme.scss';

.sidebar-component {
    height: 100vh;
    width: 262px;
    color: $forth-color;
    max-height: 100vh;
    position: fixed;
    background-color: #083349;
    
    .logo-area {
        height: 60px;
        padding-left: 30px;
    }

    .sidebar-content {
        justify-content: space-between;
        flex: 1;
        padding: 10px 0 20px;
        max-height: calc(100% - 60px);
    }

    .sidebar-resize {
        cursor: pointer;
        padding-left: 22px;
        svg {
            fill: $forth-color;
        }
        p {
            font-size: 15px;
            margin-left: 13px;
        }
    }

    .sidebar-menu {
        position: relative;
        overflow-y: auto;
        .menu-header {
            color: $forth-color;
            font-size: 17px;
            margin-right: 30px;
            padding: 14px 0 14px 30px;
            cursor: pointer;
            outline: none;
            p {
                font-size: 17px;
            }
            &:hover {
                color: white;
                text-decoration: none;
                svg {
                    fill: white;
                }
            }
        }
        .menu-item {
            color: $forth-color;
            font-size: 17px;
            margin-right: 30px;
            padding: 14px 0 14px 30px;
            border-top-right-radius: 45px;
            border-bottom-right-radius: 45px;
            p {
                font-size: 17px;
            }
            &.active, &:hover {
                color: white;
                text-decoration: none;
                svg {
                    fill: white;
                }
            }
            &.active {
                background: $fifth-color;
            }
            &.sub-menu-item {
                padding-left: 60px;
            }
        }
        .sub-menu {
            height: 0;
            overflow: hidden;
            opacity: 0;
        }

        svg {
            fill: $fifth-color;
        }
        .start-icon {
            margin-right: 12px;
        }
        .end-icon {
            margin-left: auto;
        }
    }

    &.closed {
        animation: sidebarHide 0.2s ease-out;
        width: 80px;
        .sidebar-menu {
            .menu-header {
                padding: 14px 0;
                margin-right: 0;
                justify-content: center;
                p {
                    display: none;
                }
                .end-icon {
                    display: none;
                }
                &.sub-active {
                    background: $fifth-color;
                    svg {
                        fill: white;
                    }
                }
            }
            .header-menu-item {
                padding: 14px 0;
                margin-right: 0;
                justify-content: center;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                p {
                    display: none;
                }
                .end-icon {
                    display: none;
                }
            }
            .sub-menu {
                position: absolute;
                background: $main-bg;
                left: 80px;
                top: 0;
                width: max-content;
                .sub-menu-item {
                    padding: 12px 20px 12px 15px;
                    margin-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    &.open {
        animation: sidebarShow 0.2s ease-out;
    }
}

@media screen and (max-width: 991px) {
    .sidebar-component {
        display: none;
    }
}

@keyframes sidebarShow {
    0% { width: 80px; }
    100% { width: 262px; }
}
  
@keyframes sidebarHide {
    0% { width: 262px; }
    100% { width: 80px; }
}