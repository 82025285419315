@import '../../theme.scss';

.onlinedemo-page {
    height: 100%;
    overflow-y: auto;

    .bg-container {
        max-width: 900px;
        width: 100%;
        margin: auto;
        position: relative;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .iframe-container {
        width: 100%;
        height: 100%;
        max-width: 345px;
        max-height: 586px;
        background: #F0F1F2;
        font-size: 13px;
        color: $main-bg;
        margin: 0 auto;
        font-weight: 900;
    }
}

@media screen and (min-width: 992px) {
    .onlinedemo-page {
        // padding: 0 30px;
    }
}

@media screen and (max-width: 991px) {
    .onlinedemo-page {
        padding: 15px 15px 10px 15px;
    }
}